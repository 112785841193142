import React from "react";



export default function MYAPIs() {
    return (
        <div className="flex items-center justify-center w-full">
            <p>Page is on development</p>
        </div>
    )
}