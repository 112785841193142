import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import {useNavigate} from "react-router";

interface AuthMiddlewareProps {
  children: React.ReactNode;
}

export const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const { user, isInitialized } = useAuth();
 
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = user?.access !== "" && user?.refresh !== "" && user !== null;
  const isPrivateRoute = location.pathname.startsWith('/home');
  const isPublicRoute = !isPrivateRoute;

  // Don't redirect while auth is initializing
  if (!isInitialized) {
    return <>{children}</>
  }

  // If user is not authenticated and trying to access any route other than public routes
  if (!isAuthenticated && isPrivateRoute) {
    const i = navigate(-1);
    return <Navigate to={`${i}`} replace />;
  }

  // If user is authenticated and trying to access public routes
  if (isAuthenticated && isPublicRoute) {
    const i = navigate(-1);
    return <Navigate to={`${i}`} replace />;
  }

  return <>{children}</>;
};
