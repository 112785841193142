import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";

export default function EditDialog(props: any) {
  const { openEditDialog, openEditId, handleEditDialog } = props;


  return (
    <Dialog.Root open={openEditDialog}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContentSmall overflow-hidden">
            <div className="flex justify-between items-center w-full">
          <Dialog.Title className="DialogTitle">Edit Member</Dialog.Title>
          <Dialog.Close asChild>
            <button
              onClick={() => handleEditDialog(openEditId)}
              className="IconButton"
              aria-label="Close"
            >
              <Cross2Icon width="20" height="20" />
            </button>
          </Dialog.Close>
          </div>
          

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <button
                type="button"
                className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                onClick={() => handleEditDialog(openEditId)}
              >
                Edit
              </button>
            </Dialog.Close>
          </div>
          
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

