import styled from "styled-components";
import { Link } from "react-router-dom";

import { NavItemProps } from "./types";

export const SideNavContainer = styled.nav`
  width: 20%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.text};
  position: fixed;
  top: 0;
  left: 0;
  display: block;

  @media (max-width: 768px) {
    width: 80%;
    max-width: 300px;
    z-index: 1000;
  }
`;

export const NavHeader = styled.div`
  padding: 1.25rem;
  color: ${(props) => props.theme.colors.primary};
  font-size: 1.5rem;
  font-family: "Exo", sans-serif;
  letter-spacing: 4px;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.2;
  cursor: pointer;

  img {
    max-width: 150px;
    height: auto;
  }

  @media (max-width: 768px) {
    padding: 0 1.25rem 1rem;
  }
`;

export const NavList = styled.ul`
  list-style: none;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
`;

export const NavItem = styled.li<NavItemProps>`
  padding: 1.25rem 0;
  width: 100%;
  color: ${(props) => (props.selected ? props.theme.colors.secondary : props.theme.colors.text)};
  background-color: ${(props) => (props.selected ? props.theme.colors.transition : "transparent")};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  border-radius: ${(props) => (props.selected ? "0.75rem" : 0)};
  font-weight: ${(props) => (props.selected ? "700" : "normal")};
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

export const IconWrapper = styled.span`
  margin: 0 0.25rem 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BannerWrapper = styled.div`
  padding: 0.75rem;
  border-radius: 0.375rem;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 3rem 1rem 1rem 0;
`;

export const BannerContent = styled.p`
  color: ${(props) => props.theme.colors.background};
  font-size: 0.875rem;
`;

export const Continue = styled(Link)`
  color: ${(props) => props.theme.colors.background};
  text-decoration: underline;
`;

export const AccountContainer = styled.main`
  padding-left: 20vw;
  padding-right: 3vw;
  padding-bottom: 5rem;
  max-height: 100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 3vw;
    padding-top: 3rem;
  }
`;

export const AccountNav = styled.nav`
  border-bottom: 1px solid #dfdcdc;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
`;

export const AccountNavItem = styled.div<{ selectedItem: string }>`
  color: ${(props) =>
    props.selectedItem ? `${props.theme.colors.primary}` : `${props.theme.colors.text}`};
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-bottom: ${(props) =>
    props.selectedItem ? `1px solid ${props.theme.colors.primary}` : "none"};
  font-weight: ${(props) => (props.selectedItem ? "500" : "normal")};
`;

export const AccountMainContainer = styled.div`
  display: flex;
  padding: 3rem 2rem 0;
  flex-direction: row;
  gap: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const AccountDetailsContext = styled.div<{ isActive?: boolean }>`
  border: ${(props) => (props.isActive ? "1px solid #B52C2C" : " 1px solid #f2f2f2")};
  height: fit-content;
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
`;

export const AccountDetailsTitle = styled.h2`
  font-weight: bold;
  font-size: 1rem;
`;

export const AccountDetailsTitleDescription = styled.p`
  font-size: 0.8rem;
  color: rgb(107, 123, 138);
  margin: 0;
  width: 80%;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const PaymentOptionContainer = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  line-height: 35px;
`;

export const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 80%;
  border: 5px dashed #d9dfea;
  outline: none;
`;

export const BalanceContainer = styled.div`
  padding: 1rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.transition};
  margin-top: 1rem;
`;

export const BalanceMain = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  color: #703916;
`;

export const EmptyMessage = styled.div`
  height: 55vh;
  width: 95%;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #707070;
  font-size: 0.8rem;
`;
