import axios from "axios";
import Cookies from "js-cookie";
import {
  REACT_APP_BASE_URL,
  REACT_APP_PAYMENT_URL,
  REACT_APP_PAYMENT_KEY,
  REACT_APP_WALLET_KEY,
  REACT_APP_WALLET_URL,
} from "src/helpers/constants";

async function getIdToken() {
  return Cookies.get("access");
}

export async function defaultOptionsWithToken() {
  const idToken = await getIdToken();
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  };
}

export async function formDataOptionsWithToken() {
  const idToken = await getIdToken();
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${idToken}`,
    },
  };
}

export async function paymentOptionsWithToken() {
  const idToken = await getIdToken();
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
      "Hanypay-Api-Key": `${REACT_APP_PAYMENT_KEY}`,
    },
  };
}

export async function walletOptionsWithToken() {
  const idToken = await getIdToken();
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
      "Hanypay-Api-Key": `${REACT_APP_WALLET_KEY}`,
    },
  };
}

export async function getUser() {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/auth/check/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function getRoles() {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/auth/roles/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function updateUser(userId: any, newUserData: any) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.patch(
    `${REACT_APP_BASE_URL}/auth/user/${userId}/`,
    {
      ...newUserData,
    },
    {
      ...defaultOptions,
    },
  );
  return res;
}

export async function getCountries() {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/common/country/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function uploadKYCDoc(userId: any, formData: any) {
  const formDataOptions = await formDataOptionsWithToken();
  const res = await axios.patch(
    `${REACT_APP_BASE_URL}/auth/user/${userId}/kyc-documents-upload/`,
    formData,
    {
      ...formDataOptions,
    },
  );
  return res;
}

export async function uploadBusinessDocs(userId: any, formData: any) {
  const formDataOptions = await formDataOptionsWithToken();
  const res = await axios.patch(`${REACT_APP_BASE_URL}/business/${userId}/upload-docs/`, formData, {
    ...formDataOptions,
  });
  return res;
}

export async function updateBusinessData(businessId: any, payload: any) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.patch(`${REACT_APP_BASE_URL}/business/${businessId}/`, payload, {
    ...defaultOptions,
  });
  return res;
}

export async function createBusiness(userId: any, payload: any) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.post(`${REACT_APP_BASE_URL}/business/${userId}/user-business/`, payload, {
    ...defaultOptions,
  });
  return res;
}

export async function deleteBusiness(businessId: any) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.delete(`${REACT_APP_BASE_URL}/business/${businessId}/`, {
    ...defaultOptions,
  });
  return res;
}

export async function getBusinesses(userId: string) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/business/${userId}/user-business/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function getSelectedBusiness(businessId: any) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/business/${businessId}/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function walletTransfer(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/payments/wallet-transfers/`, payload, {
    ...paymentOptions,
  });
  return res.data;
}

export async function getTransferHistory(userId: string, page?: number, filters?: any) {
  const paymentOptions = await paymentOptionsWithToken();
  let query = {};

  if (filters?.status) {
    query = {
      ...query,
      status: filters?.status,
    };
  }

  if (filters?.transaction_type) {
    query = {
      ...query,
      transaction_type: filters?.transaction_type,
    };
  }

  const res = await axios.get(
    `${REACT_APP_PAYMENT_URL}/payments/${userId}/transactions/?page=${page ?? 1}`,
    {
      ...paymentOptions,
      params: query,
    },
  );
  return res.data;
}

export async function getUserId(walletNumber: number) {
  const defaultOptions = await walletOptionsWithToken();
  const res = await axios.get(`${REACT_APP_WALLET_URL}/wallets/${walletNumber}/user/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function getParticularUser(userId: number) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/auth/user/${userId}/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function getBusinessTypes() {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/business/type/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function bulkTransfer(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(
    `${REACT_APP_PAYMENT_URL}/payments/bulk-wallet-transfers/`,
    payload,
    {
      ...paymentOptions,
    },
  );
  return res.data;
}

export async function getBusinessWallet(ownerId: string) {
  const defaultOptions = await walletOptionsWithToken();
  const res = await axios.get(`${REACT_APP_WALLET_URL}/wallets/${ownerId}/wallets/`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function getP2PRequests({
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  userData,
  page,
}: {
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  userData?: any;
  page?: number;
}) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(
    `${REACT_APP_PAYMENT_URL}/trades/v1/p2p-requests/live/?page=${page ?? 1}`,
    {
      ...paymentOptions,
      params: {
        trade_type: tradeType,
        currency_code: currencyCode || userData?.currency_code,
        payment_methods: paymentMethods,
        region: region,
        page,
      },
    },
  );
  return res.data;
}

export async function getUserP2PRequests({
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  page?: number;
}) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(
    `${REACT_APP_PAYMENT_URL}/trades/v1/p2p-requests/?page=${page ?? 1}`,
    {
      ...paymentOptions,
      params: {
        trade_type: tradeType,
        currency_code: currencyCode,
        payment_methods: paymentMethods,
        region: region,
      },
    },
  );
  return res.data;
}

export async function createTradeRequest(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/trades/v1/p2p-requests/`, payload, {
    ...paymentOptions,
  });
  return res;
}

export async function editTradeRequest(requestId: string, payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.patch(
    `${REACT_APP_PAYMENT_URL}/trades/v1/p2p-requests/${requestId}/`,
    payload,
    {
      ...paymentOptions,
    },
  );
  return res;
}

export async function currencyConversion(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/payments/exchange-rates/`, payload, {
    ...paymentOptions,
  });
  return res.data;
}

export async function createP2POrder(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/trades/v1/p2p-orders/`, payload, {
    ...paymentOptions,
  });
  return res.data;
}

export async function getP2pOrders({
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  currencyCode?: any;
  paymentMethods?: string;
  region?: string;
  page?: number;
}) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(`${REACT_APP_PAYMENT_URL}/trades/v1/p2p-orders/?page=${page ?? 1}`, {
    ...paymentOptions,
    params: {
      currency_code: currencyCode,
      payment_methods: paymentMethods,
      region: region,
    },
  });
  return res.data;
}

export async function confirmTradeOrder(orderId: string) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(
    `${REACT_APP_PAYMENT_URL}/trades/v1/p2p-orders/${orderId}/confirm/`,
    {},
    {
      ...paymentOptions,
    },
  );
  return res.data;
}

export async function verifyTradeOTP(orderId: string, otp: string) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(
    `${REACT_APP_PAYMENT_URL}/trades/v1/auth-codes/verify/`,
    {
      code: otp,
      order_id: orderId,
    },
    {
      ...paymentOptions,
    },
  );
  return res.data;
}

export async function createUserPaymentMethods(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/payments/payment-methods/`, payload, {
    ...paymentOptions,
  });
  return res.data;
}

export async function editUserPaymentMethods(id: string, payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.patch(
    `${REACT_APP_PAYMENT_URL}/payments/payment-methods/${id}/`,
    payload,
    {
      ...paymentOptions,
    },
  );
  return res.data;
}

export async function getUserPaymentMethods() {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(`${REACT_APP_PAYMENT_URL}/payments/payment-methods/`, {
    ...paymentOptions,
  });
  return res.data;
}

export async function getPaymentOptions() {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(`${REACT_APP_PAYMENT_URL}/payments/payment-options/`, {
    ...paymentOptions,
  });
  return res.data;
}

export async function acceptTradeOrder(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/trades/v1/accept-order/`, payload, {
    ...paymentOptions,
  });
  return res.data;
}

export async function getB2bLiveRequests({
  businessId,
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  businessId: string;
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  page?: number;
}) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(
    `${REACT_APP_PAYMENT_URL}/trades/v1/b2b-requests/live/?page=${page ?? 1}`,
    {
      ...paymentOptions,
      params: {
        business_id: businessId,
        trade_type: tradeType,
        currency_code: currencyCode,
        payment_methods: paymentMethods,
        region: region,
      },
    },
  );
  return res.data;
}

export async function getBusinessTrades({
  businessId,
  tradeType,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  businessId: string;
  tradeType: string;
  currencyCode: any;
  paymentMethods: string;
  region: string;
  page?: number;
}) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(
    `${REACT_APP_PAYMENT_URL}/trades/v1/b2b-requests/?page=${page ?? 1}`,
    {
      ...paymentOptions,
      params: {
        business_id: businessId,
        trade_type: tradeType,
        currency_code: currencyCode,
        payment_methods: paymentMethods,
        region: region,
      },
    },
  );
  return res.data;
}

export async function getB2bOrders({
  businessId,
  currencyCode,
  paymentMethods,
  region,
  page,
}: {
  businessId: string;
  currencyCode?: any;
  paymentMethods?: string;
  region?: string;
  page?: number;
}) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.get(`${REACT_APP_PAYMENT_URL}/trades/v1/b2b-orders/?page=${page ?? 1}`, {
    ...paymentOptions,
    params: {
      business_id: businessId,
      currency_code: currencyCode,
      payment_methods: paymentMethods,
      region: region,
    },
  });
  return res.data;
}

export async function createBusinessTradeRequest(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/trades/v1/b2b-requests/`, payload, {
    ...paymentOptions,
  });
  return res;
}

export async function createBusinessTradeOrder(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(`${REACT_APP_PAYMENT_URL}/trades/v1/b2b-orders/`, payload, {
    ...paymentOptions,
  });
  return res.data;
}

export async function editBusinessTradeRequest(requestId: string, payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.patch(
    `${REACT_APP_PAYMENT_URL}/trades/v1/b2b-requests/${requestId}/`,
    payload,
    {
      ...paymentOptions,
    },
  );
  return res;
}

export async function confirmBusinesTradeOrder(orderId: string, businessId: string) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(
    `${REACT_APP_PAYMENT_URL}/trades/v1/b2b-orders/confirm/`,
    { order_id: orderId, business_id: businessId },
    {
      ...paymentOptions,
    },
  );
  return res.data;
}

export async function deleteP2pTradeRequest(id: string) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.delete(`${REACT_APP_PAYMENT_URL}/trades/v1/p2p-requests/${id}/`, {
    ...paymentOptions,
  });
  return res;
}

export async function deleteb2b2pTradeRequest(id: string) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.delete(`${REACT_APP_PAYMENT_URL}/trades/v1/b2b-requests/${id}/`, {
    ...paymentOptions,
  });
  return res;
}

export async function acceptB2bTradeOrder(payload: any) {
  const paymentOptions = await paymentOptionsWithToken();
  const res = await axios.post(
    `${REACT_APP_PAYMENT_URL}/trades/v1/b2b-requests/accept-order/`,
    payload,
    {
      ...paymentOptions,
    },
  );
  return res.data;
}

//Teams
export async function getTeamMembers(team_id: string) {
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.get(`${REACT_APP_BASE_URL}/teams/${team_id}/members`, {
    ...defaultOptions,
  });
  return res.data;
}

export async function createTeamMember(payload: any) {
  const defaultOptions = await defaultOptionsWithToken();
  const { email, phone, role, firstname, lastname, team } = payload;
  const res = await axios.post(
    `${REACT_APP_BASE_URL}/teams/invite-member/`,
    {
      email, phone, role, firstname, lastname, team
    },
    {
      ...defaultOptions,
    },
  );
  return res;
}


export async function confirmTeamMemberDetails(team_member_id: string) {
  
  const res = await axios.get(`${REACT_APP_BASE_URL}/teams/members/${team_member_id}/check-member-exists/`);
  return res.data;
}

export async function completeTeamMemberPassword(payload: any) {
  
  const { email, password} = payload;
  const res = await axios.post(
    `${REACT_APP_BASE_URL}/teams/team-member/set-password/`,
    {
      email, password
    },
  );
  return res;
}


export async function deleteTeamMember(payload: any) {
  const {team_id, team_member_id} = payload;
  const defaultOptions = await defaultOptionsWithToken();
  const res = await axios.delete(`${REACT_APP_BASE_URL}/teams/${team_id}/members/${team_member_id}/member-details/`, {
    ...defaultOptions,
  });
  return res;
}