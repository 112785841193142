import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { deleteTeamMember } from "src/services";
import { useToast } from "../../common/ui/use-toast";
import { useMutation } from "@tanstack/react-query";




export default function DeleteDialog(props: any) {
  const { openDeleteDialog, openDeleteId, handleDeleteDialog } = props;
  const [deleteText, setDeleteText] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();


  const deleteTeamMemberMutation = useMutation({
    mutationFn: deleteTeamMember,
    onSuccess: async () => {
      toast({
        variant: "default",
        description: "Team member was created successfully.",
      });
      setLoading(false);
    },
    onError: (error: any) => {
      const message = error?.response?.data;
      if (message && typeof message === "object") {
        Object.keys(message).forEach((field) => {
          toast({
            variant: "destructive",
            title: `${field.charAt(0).toUpperCase() + field.slice(1)}`,
            description: message.message,
          });
        });
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
        });
      }
      setLoading(false);
    },
  });

  const handleDeleteTeamMember = async () => {
    setLoading(true);
    const payload = {
      team_member_id: openDeleteId?.team_member_id,
      team_id: openDeleteId?.team_id
    };

    try {
      deleteTeamMemberMutation.mutate(payload);
    } catch (error) {
      console.error("Creation of Team member failed:", error);
      
    }
  };

  return (
    <Dialog.Root open={openDeleteDialog}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContentSmall overflow-hidden">
          <div className="flex justify-between items-center w-full">
            <Dialog.Title className="DialogTitle">Delete Member</Dialog.Title>
            <Dialog.Close asChild>
              <button
                onClick={() => handleDeleteDialog(openDeleteId?.id, openDeleteId?.name)}
                className="IconButton"
                aria-label="Close"
              >
                <Cross2Icon width="20" height="20" />
              </button>
            </Dialog.Close>
          </div>

          <Dialog.Description className="DialogDescription">
            Are you sure you want to delete <span className="font-bold">{openDeleteId?.name}</span>?
          </Dialog.Description>

          <div>
            <label className="block text-gray-900">
              Type <span className="font-bold">delete</span> to confirm
            </label>

            <input
              type="text"
              name="deleteText"
              id="deleteText"
              className="w-full rounded-md border py-1.5 shadow-sm border-slate-200 focus:ring-2 focus:ring-inset focus:ring-red-500 text-sm focus:outline-none px-2"
              onChange={(e) => setDeleteText(e.target.value)}
              disabled={loading}
            />
          </div>

          <div className="flex justify-end gap-2 items-center mt-[3rem]">
            <Dialog.Close asChild>
              <button
                type="button"
                className="flex items-center justify-center py-1 rounded-md bg-white border border-red-500 px-6 text-sm font-semibold leading-6 text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                onClick={() => handleDeleteDialog(openDeleteId?.id, openDeleteId?.name, openDeleteId?.team_id, openDeleteId?.team_member_id)}
              >
                Cancel
              </button>
            </Dialog.Close>
            <button
              type="button"
              className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 disabled:bg-gray-400 disabled:cursor-not-allowed"
              onClick={handleDeleteTeamMember}
              disabled={deleteText.toLowerCase() !== "delete" || loading}
            >
              {loading ? "Deleting..." : "Delete"}
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
