import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EditIcon, MoreVerticalIcon, TrashIcon } from "lucide-react";

export default function Dropdown(props) {
  const { id, handleEditDialog, handleDeleteDialog, name, team_id, team_member_id } = props;
  return (
    <>
      <Menu>
        <MenuButton>
          <MoreVerticalIcon />
        </MenuButton>

        <MenuItems
          transition
          anchor="bottom end"
          className="w-52 origin-top-right rounded-xl border border-slate-200 bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none divide-y divide-gray-100"
        >
          <MenuItem>
            <button
              className="hover:bg-black hover:text-white flex w-full items-center gap-2 rounded-lg py-1.5 px-3"
              onClick={() => handleEditDialog(id)}
            >
              <EditIcon className="size-4" />
              Edit
            </button>
          </MenuItem>
          <MenuItem>
            <button
              className="hover:bg-black hover:text-white flex w-full items-center gap-2 rounded-lg py-1.5 px-3"
              onClick={() => handleDeleteDialog(id, name, team_id, team_member_id)}
            >
              <TrashIcon className="size-4" />
              Delete
            </button>
          </MenuItem>
        </MenuItems>
      </Menu>
    </>
  );
}
