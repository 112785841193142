import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ROLE } from ".";

export default function InviteTeamMembers(props: any) {
  const {
    handleCreateTeamMember,
    initialValues,
    validationSchema,
    selectedRole,
    setSelectedRole,
    ROLES,
    setCreateTeamLayout,
    loading
  } = props;

  return (
    <>
      <div className="flex flex-col items-center justify-center space-y-1">
        <h1 className="font-bold">Invite Team Member</h1>
        <h3 className="text-sm">Fill in the form to invite a team member</h3>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleCreateTeamMember}
      >
        {({ isSubmitting, errors, touched, setFieldValue }) => (
          <Form>
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div className="flex flex-col space-y-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="text-sm font-medium leading-6 text-gray-900"
                  >
                    First Name
                  </label>
                  <Field
                    id="firstName"
                    name="firstName"
                    type="text"
                    className="rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6 w-full"
                  />
                  <ErrorMessage name="firstName" component="div" className="text-red-500 text-xs" />
                </div>

                <div>
                  <label
                    htmlFor="lastName"
                    className="mt-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Last Name
                  </label>
                  <Field
                    id="lastName"
                    name="lastName"
                    type="text"
                    className="rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6 w-full"
                  />
                  <ErrorMessage name="lastName" component="div" className="text-red-500 text-xs" />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email Address
                  </label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    autoComplete=""
                    className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500 text-xs" />
                </div>

                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone Number
                  </label>
                  <Field
                    id="phone"
                    name="phone"
                    type="tel"
                    className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                  />
                  <ErrorMessage name="phone" component="div" className="text-red-500 text-xs" />
                </div>
                <div>
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Role
                  </label>
                  <Field
                    id="role"
                    as="select"
                    name="role"
                    value={selectedRole}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setFieldValue("role", e.target.value);
                      setSelectedRole(e.target.value);
                    }}
                    className={`block w-full rounded-md border bg-white px-4 ${
                      errors.role && touched.role ? "border-red-500" : "border-gray-300"
                    } px-4 py-2 shadow-sm ring-1 ring-inset ring-gray-900 bg-white placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6`}
                  >
                    <option value="">Select a role...</option>
                    {ROLES?.roles?.map((role: ROLE) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="country" component="div" className="text-red-500 text-xs" />
                </div>

                <div className="pt-[3rem] flex items-center justify-between w-full gap-4">
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="flex w-full justify-center py-2 rounded-md bg-white text-sm font-semibold  items-center text-black shadow-sm hover:bg-gray-800 hover:text-white transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 border border-gray-300"
                    onClick={() => setCreateTeamLayout(false)}
                  >
                    cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isSubmitting || loading}
                    className="flex w-full disabled:cursor-not-allowed justify-center py-2 rounded-md bg-gray-900 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                  >
                    {isSubmitting || loading ? "Inviting member..." : "Invite member"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
