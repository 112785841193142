import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../../assests/images/logo-dark-h.png";
import { completeTeamMemberPassword, confirmTeamMemberDetails } from "src/services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useNavigate} from "react-router";
import { useToast } from "../common/ui/use-toast";
import { LoaderCircle } from "lucide-react";
import * as Yup from "yup";

export type User = {
  email: string;
  password: string;
};

export const initialValues = {
  email: "",
  password: "",
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .max(30, "Email is limited to 100 characters.")
    .trim()
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, "Invalid email address")
    .required("Email required"),
  password: Yup.string()
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .min(8, "Password must be at least 8 characters long")
    .required("Password required"),
});

export default function VerifyTeamMember() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: teamMember,
    refetch,
  } = useQuery({
    queryKey: ["teamMemberDetails"],
    queryFn: () => confirmTeamMemberDetails(id as string),
  });

  const completeTeamMemberPasswordMutation = useMutation({
    mutationFn: completeTeamMemberPassword,
    onSuccess: async () => {
      toast({
        variant: "default",
        description: "Cofirmation completed successfully.",
      });
      setLoading(false);
      navigate("/");
    },
    onError: (error: any) => {
      const message = error?.response?.data;
      if (message && typeof message === "object") {
        Object.keys(message).forEach((field) => {
          toast({
            variant: "destructive",
            title: `${field.charAt(0).toUpperCase() + field.slice(1)}`,
            description: message.message,
          });
        });
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
        });
      }
      setLoading(false);
    },
  });

  const handleConfirmTeamMember = async (
    userData: User,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setLoading(true);
    const payload = {
      email: userData.email,
      password: userData?.password
    };

    try {
      completeTeamMemberPasswordMutation.mutate(payload);
    } catch (error) {
      console.error("Creation of Team member failed:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen w-full space-y-16 mx-5 md:mx-10 mt-[1rem]">
      <img className="mix-blend-multiply" src={logo} alt="logo" width={150} height={150} />

      {!isLoading && teamMember && teamMember?.code === "Exists" ? (
        <>
          <div className="flex flex-col items-center justify-center space-y-1">
            <h1 className="font-bold">Complete Membership</h1>
            <h3 className="text-sm">Fill in the form to complete your membership</h3>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleConfirmTeamMember}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <Form>
                <div className="mx-auto w-full max-w-sm lg:w-96">
                  <div className="flex flex-col space-y-4">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email Address
                      </label>
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete=""
                        className="block w-full rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6"
                      />
                      <ErrorMessage name="email" component="div" className="text-red-500 text-xs" />
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="mt-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        className="rounded-md border-0 px-2 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-200 sm:text-sm sm:leading-6 w-full"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500 text-xs"
                      />
                    </div>

                    <div className="pt-[3rem] flex items-center justify-between w-full gap-4">
                      <button
                        type="submit"
                        disabled={isSubmitting || loading}
                        className="flex w-full disabled:cursor-not-allowed justify-center py-2 rounded-md bg-gray-900 px-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-white hover:text-gray-900 border border-gray-900 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                      >
                        {isSubmitting || loading ? "Confirming..." : "Confirm"}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : !isLoading && teamMember && teamMember?.code === "Active" ? (
        <div className="flex flex-col items-center justify-center space-y-1">
          <h1 className="font-bold">You&apos;ve already completed your registration</h1>
          <div className="text-sm">Click on the <span className="underline text-red-500" onClick={() => {navigate("/")}}>link</span> to login.</div>
        </div>
      ) : !isLoading && (!teamMember || teamMember) ? (
        <div className="flex flex-col items-center justify-center space-y-1">
          <h1 className="font-bold">Team Member Not Found</h1>
          <h3 className="text-sm">Team Member not found. Please check your id.</h3>
        </div>) : (
        <div className="flex items-center justify-center">
          <LoaderCircle className="animate-spin h-5 w-5" />
        </div>
      )}
    </div>
  );
}
