import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import ForgotPassword from "./components/auth/ForgotPassword";
import VerifyEmail from "./components/auth/VerifyEmail";
import ErrorPage from "./components/common/ErrorPage";
import Marketplace from "./components/Marketplace";
import Wallets from "./components/Wallets";
import Account from "./components/Account";
import History from "./components/History/index";
import Transfer from "./components/Transfer";
import SuccessPage from "./components/auth/SuccessPage";
import { AuthProvider } from "./hooks/useAuth";
import { PrivateRoute } from "./helpers/PrivateRoute";
import NavLayout from "./components/common/NavLayout";
import BusinessSelect from "./components/common/BusinessSelect";
import ResetPassword from "./components/auth/ResetPassword";
import BusinessComponent from "./components/BusinessComponent";
import Trading from "./components/Trading";
import { AuthMiddleware } from "./helpers/AuthMiddleware";
import VerifyTeamMember from "./components/auth/VerifyTeamMember";

const RouteWrapper: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <AuthMiddleware>
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/" element={<SignIn />} />
            <Route path="/otp-verify" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/account-success" element={<SuccessPage />} />
            <Route path="/member-login/:id" element={<VerifyTeamMember />} />
            <Route path="/home" element={<PrivateRoute />}>
              <Route element={<NavLayout />}>
                <Route path="/home/transfer" element={<Transfer />} />
                <Route path="/home/wallets" element={<Wallets />} />
                <Route path="/home/history" element={<History />} />
                <Route path="/home/marketplace" element={<Marketplace />} />
                <Route path="/home/account" element={<Account />} />
                <Route path="/home/business/:id" element={<BusinessSelect />} />
                <Route path="/home/business" element={<BusinessComponent />} />
                <Route path="/home/trading" element={<Trading />} />
              </Route>
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </AuthMiddleware>
      </AuthProvider>
    </Router>
  );
};

export default RouteWrapper;
