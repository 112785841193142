import { useState } from "react";
import Profile from "./Profile";
import KYCVerification from "./KYCVerification";
import { AccountContainer, AccountNav, AccountNavItem } from "./common/Styles";
import MYAPIs from "./MYAPIs";
import TeamManagement from "./team";

const NAVITEMS = [{ name: "Profile" }, { name: "KYC Verification" }, { name: "Team Management" }, { name: "API" }];

const Account = () => {
  const [selectedItem, setSelectedItem] = useState<string>(NAVITEMS[0].name);

  const isProfile = selectedItem === NAVITEMS[0].name;
  const isKYC = selectedItem === NAVITEMS[1].name;
  const isTeam = selectedItem === NAVITEMS[2].name;

  return (
    <AccountContainer>
      <AccountNav>
        {NAVITEMS?.map(({ name }) => (
          <AccountNavItem
            key={name}
            selectedItem={selectedItem === name ? name : ""}
            onClick={() => setSelectedItem(name)}
          >
            {name}
          </AccountNavItem>
        ))}
      </AccountNav>

      {isProfile ? <Profile /> : isKYC ? <KYCVerification /> : isTeam ? <TeamManagement /> : <MYAPIs />}
    </AccountContainer>
  );
};

export default Account;
