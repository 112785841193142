import React from "react";
import Dropdown from "./dropdown";

export default function MyTeamMembers(props: any) {
  const {
    id,
    firstname,
    lastname,
    email,
    role,
    is_active,
    handleDeleteDialog,
    handleEditDialog,
    userDataResponse,
  } = props;
  return (
    <div className="flex items-start w-full justify-between">
      <div className="flex flex-col w-[80%]">
        <p className="font-bold line-clamp-1">{firstname + " " + lastname}</p>
        <div className="flex items-center space-x-1 w-full text-[#8a8a8a] text-sm italic">
          <p>{email}</p>
          <span>-</span>
          <span>{role}</span>
        </div>
      </div>
      <div className="w-[10%] flex items-center justify-end">
        <div className="w-full flex items-center justify-between">
          <span
            className={`${
              is_active ? "bg-green-500" : "bg-red-500"
            } text-white text-xs flex items-center rounded-md py-1 justify-center w-[80%]`}
          >
            {is_active ? "active" : "inactive"}
          </span>
          <div className="w-[5%] flex items-center justify-end">
            {userDataResponse?.team_member_id === id ? null : (
              <Dropdown
                id={id}
                handleDeleteDialog={handleDeleteDialog}
                handleEditDialog={handleEditDialog}
                name={firstname + " " + lastname}
                team_id={userDataResponse?.team_id}
                team_member_id={id}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
