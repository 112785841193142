import React, { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createTeamMember, getRoles, getTeamMembers, getUser } from "src/services";
import * as Yup from "yup";
import { useToast } from "../common/ui/use-toast";
import InviteTeamMembers from "./InviteTeamMembers";
import { LoaderCircle } from "lucide-react";
import Dropdown from "./dropdown";
import EditDialog from "./openMore/editdialog";
import DeleteDialog from "./openMore/deleteDialog";
import MyTeamMembers from "./teamMembers";

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  phone: any;
  role: string;
};

export type ROLE = {
  id: string;
  name: string;
};

export const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  role: "",
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .max(30, "Email is limited to 100 characters.")
    .trim()
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, "Invalid email address")
    .required("Required"),
  firstName: Yup.string()
    .trim()
    .min(2, "First name should be at least 2 characters")
    .max(30, "First name limited to 30 characters only.")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .max(30, "Last name limited to 30 characters only.")
    .min(2, "Last name should be at least 2 characters")
    .required("Required"),
  phone: Yup.string()
    .trim()
    .matches(/^\d{10,15}$/, "Invalid phone number")
    .required("Required"),
  role: Yup.string().required("Required"),
});

export default function TeamManagement() {
  const [selectedRole, setSelectedRole] = useState("");
  const [createTeamLayout, setCreateTeamLayout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openEditId, setOpenEditId] = useState("");
  const [openDeleteId, setOpenDeleteId] = useState({
    id: "",
    name: "",
    team_id: "",
    team_member_id: "",
  });
  const { toast } = useToast();

  const {
    isLoading,
    isError,
    data: userDataResponse,
  } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  const {
    isLoading: teamsLoading,
    isError: teamsError,
    data: myTeamMembers,
    refetch,
  } = useQuery({
    queryKey: ["membersDetails", userDataResponse?.team_id],
    queryFn: () =>
      userDataResponse?.team_id ? getTeamMembers(userDataResponse.team_id) : Promise.resolve([]),
    enabled: !!userDataResponse?.team_id,
  });

  const {
    data: ROLES,
    isLoading: loadingRoles,
    error: rolesError,
  } = useQuery({ queryKey: ["roles"], queryFn: getRoles });

  const createTeamMemberMutation = useMutation({
    mutationFn: createTeamMember,
    onSuccess: async () => {
      toast({
        variant: "default",
        description: "Team member was created successfully.",
      });
      setLoading(false);
      await refetch();
    },
    onError: (error: any) => {
      const message = error?.response?.data;
      if (message && typeof message === "object") {
        Object.keys(message).forEach((field) => {
          toast({
            variant: "destructive",
            title: `${field.charAt(0).toUpperCase() + field.slice(1)}`,
            description: message.message,
          });
        });
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
        });
      }
      setLoading(false);
    },
  });

  const handleCreateTeamMember = async (
    userData: User,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setLoading(true);
    const payload = {
      email: userData.email,
      firstname: userData.firstName,
      lastname: userData.lastName,
      phone: userData.phone,
      role: userData.role,
      team: userDataResponse?.team_id,
    };

    try {
      createTeamMemberMutation.mutate(payload);
    } catch (error) {
      console.error("Creation of Team member failed:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEditDialog = (id: string) => {
    setOpenEditDialog(!openEditDialog);
    setOpenEditId(id);
  };

  const handleDeleteDialog = (
    id: string,
    name: string,
    team_id: string,
    team_member_id: string,
  ) => {
    setOpenDeleteDialog(!openDeleteDialog);
    setOpenDeleteId({ id, name, team_id, team_member_id });
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-4 mt-[2rem]">
      {!userDataResponse?.team_id ? (
        <p className="flex items-center justify-center">
          Sorry!, you can&apos;t invite a team member. You need to create a business first!
        </p>
      ) : (
        <div className="flex flex-col w-full space-y-8">
          <div className="flex items-center justify-between w-full">
            <h1 className="font-bold">Team Members</h1>
            <button
              className="flex items-center justify-center py-1 bg-white text-red-500 border border-red-500 rounded-md px-4 hover:bg-red-500 hover:text-white"
              onClick={() => setCreateTeamLayout(true)}
            >
              + Invite
            </button>
          </div>
          {!createTeamLayout ? (
            !teamsLoading && myTeamMembers && myTeamMembers?.results?.length ? (
              myTeamMembers?.results.map(
                ({ id, email, firstname, lastname, role, is_active, status, date_joined }: any) => {
                  return (
                    <div key={id}>
                      <MyTeamMembers
                        id={id}
                        firstname={firstname}
                        lastname={lastname}
                        email={email}
                        role={role}
                        is_active={is_active}
                        handleDeleteDialog={handleDeleteDialog}
                        handleEditDialog={handleEditDialog}
                        userDataResponse={userDataResponse}
                      />
                    </div>
                  );
                },
              )
            ) : !teamsLoading && (!myTeamMembers || !myTeamMembers?.results?.length) ? (
              <p className="flex items-center justify-center">
                You&apos;ve no team members at the moment, you can invite a team member by clicking
                on the button.
              </p>
            ) : (
              <div className="flex items-center justify-center">
                <LoaderCircle className="animate-spin h-5 w-5" />
              </div>
            )
          ) : (
            <InviteTeamMembers
              handleCreateTeamMember={handleCreateTeamMember}
              initialValues={initialValues}
              validationSchema={validationSchema}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              ROLES={ROLES}
              setCreateTeamLayout={setCreateTeamLayout}
              loading={loading}
            />
          )}
        </div>
      )}
      <EditDialog
        openEditDialog={openEditDialog}
        openEditId={openEditId}
        handleEditDialog={handleEditDialog}
      />
      <DeleteDialog
        openDeleteDialog={openDeleteDialog}
        openDeleteId={openDeleteId}
        handleDeleteDialog={handleDeleteDialog}
      />
    </div>
  );
}
